
export let apiBaseUrl;

switch (location.host) {
    case '192.168.100.5':
        apiBaseUrl='http://localhost:3000/masterPanel';
      
      break;
    case '127.0.0.1:5500':
      apiBaseUrl = 'http://localhost:3000/masterPanel';
    
      break;
    case '127.0.0.1:5502':
        apiBaseUrl='http://localhost:3000/masterPanel';
      
      break;
  
    default:
  
      apiBaseUrl = 'https://newapi.yamasha.in/masterPanel';

      
  }

  // this is testing comment 
  