import axios from 'axios';
import store from 'store';
import Swal from 'sweetalert2';
import { apiBaseUrl } from './config';
import { unixTimeStampInSeconds } from './utility';

export const getMpAuthData = () => {
    let mtaMpAuthData = store.get('yamashaMpAuthData');

    if (!mtaMpAuthData) {
        mtaMpAuthData = {
            accessToken: undefined,
            refreshToken: undefined,
            updatedAt: undefined
        };
    }

    return mtaMpAuthData;
};
export const setMpAuthData = ({accessToken, refreshToken}) => {
    store.set('yamashaMpAuthData', {
        accessToken,
        refreshToken,
        updatedAt: unixTimeStampInSeconds()
    });
};

const authHelper = {
    validate: (forceStatus) => {
        return new Promise((resolve, reject) => {
            (async ()=>{ 
                try {
                forceStatus = forceStatus || false;

                const path = window.location.pathname;
                let page = path.split('/').pop();

                if(page ==='login' || page ==='register'){
                    page =page+'.html';
                }
    
        //    console.log(getMpAuthData().refreshToken);
                if (!getMpAuthData().refreshToken) {

                    
                    if (page !== 'login.html' && page !== 'register.html') {
                       return location.replace('./login.html');
                    // console.log('redirect to login 1');
                    }
                    resolve(true);
                }
    
                let lastUpdatedDiff = unixTimeStampInSeconds() - getMpAuthData().updatedAt;
                if (forceStatus) {
                    lastUpdatedDiff = lastUpdatedDiff + 60 * 8;
                }
                console.log({lastUpdatedDiff});
                let requiredReloadOnResult = false;
    
                
    
    
    
    
                if (lastUpdatedDiff > 60 * 8) {
                    requiredReloadOnResult = true;
    
    
    
                    Swal.fire({
                        title: 'Validating Connection...',
                        showConfirmButton: false,
    
    
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
    
                    });
    
                    setTimeout(() => {
                        Swal.close();
                        location.replace('./login.html');
                        // console.log('redirect to login 2');
                    }, 1000 * 60 * 2);
    
                   
    
                }
    
                if (lastUpdatedDiff > 60 * 5) {
                    // refresh the token 
    
    
    
    
                    axios({
                        method: 'get',
                        url: apiBaseUrl + '/auth/refresh-token',
                        headers: { Authorization: `Bearer ${  getMpAuthData().refreshToken}` }
    
                    })
                        .then(function (response) {
                            console.log(response.data);
    
                            if (response.data.status === 'success') {
    
    
                                const { accessToken } = response.data;
                               
                                setMpAuthData({ accessToken, refreshToken:getMpAuthData().refreshToken });
    
                                resolve(true);
                                if (page === 'login.html' || page === 'register.html') {
                                    location.replace('./index.html');
                                }
    
                                if (requiredReloadOnResult) {
    
                                    location.reload();
                                    // console.log('reloading page');
                                }
    
                            } else {
                                authHelper.logout();
                                reject(false);
                            }
    
                        }).catch(function (error) {
                            console.log(error);
    
                            if (error.response.status === 401) {
    
                                authHelper.logout();
                                reject(false);
                            }
    
                        });
                }
                if (lastUpdatedDiff < 60 * 5) {
                    resolve(true);
                    // console.log(page);
                    if (page === 'login.html' || page === 'register.html') {
                        location.replace('./index.html');
                    }
    
                    
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Inactive from Last 5 mins',
                            text: 'refresh page to fix this',
                            showConfirmButton: true,
        
        
                            allowOutsideClick: false,
                            confirmButtonText: 'Continue',
        
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                authHelper.validate();
                            }
                        });
                    }, (60 * 5 - lastUpdatedDiff) * 1000 );
                }
    
                
    
    
                // adding event listener for logout button
                var elements = document.getElementsByClassName('logout-on-click');
    
                var myFunction = function () {
                    authHelper.logout();
                    // console.log('this . logout trigged');
                };
    
                for (var i = 0; i < elements.length; i++) {
                    elements[i].addEventListener('click', myFunction, false);
                }
    
            } catch (error) {
                console.log(error);
            }})();
        });
        


    },

    logout: () => {
        store.remove('yamashaMpAuthData');
        authHelper.validate();
    }


};



export default authHelper;