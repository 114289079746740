import axios from 'axios';
import authHelper, { getMpAuthData } from './authHelper';
import { apiBaseUrl } from './config';
import { Toast } from './utility';

export function axiosErrorHandler(error) {
  console.log(error);
  if (error?.response?.status === 401) {

    authHelper.validate(true);
  }

  if (error?.code === 'ERR_NETWORK') {
    Toast.fire({
      icon: 'error',
      title: error?.message
    });
  }

  if (error?.response?.status === 422) {

    Toast.fire({
      icon: 'error',
      title: `${Object.keys(error.response.data.errors[0])}:${error.response.data.errors[0][Object.keys(error.response.data.errors[0])]}`
    });
  }
}

export function axiosResponseHandler(response) {
  const res = response.data;
  if (res.status === 'error') {
    Toast.fire({
      icon: 'error',
      title: res.msg
    });

    return false;
  }
}


export const callTheBaseServerGet = axios.create({
  method: 'get',
  baseURL: apiBaseUrl,
  timeout: 60 * 1000,
  headers: { Authorization: `Bearer ${ getMpAuthData().accessToken}` }
});



export const callTheBaseServerPost = axios.create({
  method: 'post',
  baseURL: apiBaseUrl,
  timeout: 60 * 1000,
  headers: { Authorization: `Bearer ${getMpAuthData().accessToken}` }
});