import store from 'store';
import Swal from 'sweetalert2';

export function check_overflow() {
    function isElementOverflowing(element) {

        var overflowX = element.offsetWidth < element.scrollWidth,
            overflowY = element.offsetHeight < element.scrollHeight;
        console.log(overflowX + '+' + overflowY);
        return (overflowX || overflowY);
    }

    function wrapContentsInMarquee(element) {

        var marquee = document.createElement('marquee'),
            contents = element.innerText;

        marquee.innerText = contents;
        element.innerHTML = '';
        element.appendChild(marquee);
    }

    var element = document.getElementsByClassName('overmarquee');



    for (let i = 0; i < element.length; i++) {
        // element = element[i];
        console.log(element[i]);
        if (!element[i]) {
            break;

        }
        if (isElementOverflowing(element[i])) {
            wrapContentsInMarquee(element[i]);

        }


    }
}

//  short hand 
export var byId = function (id) { return document.getElementById(id); };



// function for updating user name in header

export function fetch_header_data() {

    //setting OW_NAME_TEXT
    var OW_NAME_TEXT = document.getElementsByClassName('OW_NAME_TEXT');
    // console.log(OW_NAME_TEXT);
    var i = 0;
    while (OW_NAME_TEXT.length > i) {
        OW_NAME_TEXT[i].innerHTML = store.get('yamasha_master_data').OW_NAME;
        i++;
    }
    var header_profile_img = byId('header_profile_img');
    header_profile_img.src = store.get('yamasha_master_data').LOGO_LINK;


}



export const addDNoneClass = (htmlElement, status) => {
    if (status === true) {
        if(!htmlElement.classList.contains('d-none')){
            htmlElement.classList.add('d-none');
        }
       
    }
    if (status === false) {
        if(htmlElement.classList.contains('d-none')){
            htmlElement.classList.remove('d-none');
        }
       
    }

};



export function btnLoading(btn, msg, status) {

    if (!btn) {
        console.log('error in btn_loading');
    }

    const btn_id = btn.id;
    // console.log(btn_id);
    if (status === true) {
        btn.disabled = true;
        const old_data = btn.innerHTML;
        btn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ${msg}
        <span id='${btn_id + 'old_data'}' class='d-none'>${old_data}</span>`;
    }
    if (status === false) {
        btn.disabled = false;
        const old_data_div = byId(btn_id + 'old_data');
        const old_data = old_data_div.innerHTML;
        btn.innerHTML = `${old_data}`;
    }


}

export const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});





export const unixTimeStampInSeconds = () => { return Math.floor(Date.now() / 1000); };


export const insertDataToElementUsingClassName = (className, data) => {
    // adding event listener for logout button
    const elements = document.getElementsByClassName(className);



    for (var i = 0; i < elements.length; i++) {
        elements[i].innerHTML = data;
    }
};